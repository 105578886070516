import * as t from 'io-ts';
import { pipe } from 'fp-ts/function';

const LazyLoadInitializedEvent = t.type({
  detail: t.type({
    instance: t.type({
      update: t.Function,
    }),
  }),
});

const main = (window: Window) => {
  window.lazyLoadOptions = {
    // Your custom settings go here
  };
  window.addEventListener(
    'LazyLoad::Initialized',
    (event: unknown) => {
      pipe(event, LazyLoadInitializedEvent.decode);
    },
    false
  );
};

main(window);
